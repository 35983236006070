import { List } from '@material-ui/core';
import ContactPhone from 'components/shared/contact-phone';
import Button from 'components/shared/button';
import ListItemWithBullet from 'components/shared/list-item-with-bullet';
import Text from 'components/shared/text';
import React, { FC } from 'react';
import theme from 'src/shared/theme';
import styled, { css } from 'styled-components';
import useStoredReservation from 'src/shared/hooks/useStoredReservation';

interface Props {
  className?: string;
  pageContent: {
    title: string;
    reservationUsableFor: string[];
    surgeries: string;
    description: string;
    cancelReservationTitle: string;
    cancelReservationDescription: string;
    cancelReservationButton: string;
  };
}

const Title = styled(Text)`
  margin-bottom: ${theme.spacing(4)};
`;
const SurgeriesText = styled(Text)`
  margin-top: ${theme.spacing(10)};
  margin-bottom: ${theme.spacing(12)};
`;

const SurgeriesTextWithLink: FC<{
  className?: string;
  textWithAnchorTag: string;
}> = props => {
  try {
    const [beforeLink, linkTextWithEndingTag] = props.textWithAnchorTag.split(
      `<a>`
    );
    const [linkText, afterLink] = linkTextWithEndingTag.split(`</a>`);

    return (
      <>
        {beforeLink}
        <ContactPhone color="primary">{linkText}</ContactPhone>
        {afterLink}
      </>
    );
  } catch (_) {
    throw new Error(
      `Could not parse the reservations description surgeries text for phone link extraction. Failing text: ${props.textWithAnchorTag}`
    );
  }
};

const DeleteReservationButton = styled(Button)`
  margin-top: ${theme.spacing(8)};
`;

const ReservationsDescription: FC<Props> = props => {
  const content = props.pageContent;
  const { queryParamsForDelete } = useStoredReservation();

  return (
    <div className={props.className}>
      <section>
        <Title variant="h4" variantMapping={{ h4: `h2` }}>
          {content.title}
        </Title>
        <List dense>
          {content.reservationUsableFor.map(usage => (
            <ListItemWithBullet key={usage}>
              <Text>{usage}</Text>
            </ListItemWithBullet>
          ))}
        </List>
        <SurgeriesText fontWeight="bold">
          <SurgeriesTextWithLink textWithAnchorTag={content.surgeries} />
        </SurgeriesText>
        <Text>{content.description}</Text>
      </section>

      <section
        css={css`
          margin-top: ${theme.spacing(20)};
        `}
      >
        <Title variant="h4" variantMapping={{ h4: `h2` }}>
          {content.cancelReservationTitle}
        </Title>
        <Text>{content.cancelReservationDescription}</Text>
        <DeleteReservationButton
          to={`/rezervace/zrusit${queryParamsForDelete}`}
          color="secondary"
          variant="contained"
          data-testid="delete-button"
        >
          {content.cancelReservationButton}
        </DeleteReservationButton>
      </section>
    </div>
  );
};

export default ReservationsDescription;
