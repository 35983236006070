/* eslint-disable react/jsx-no-duplicate-props */
import {
  ButtonBase,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import Text from 'components/shared/text';
import React, { FC, useState } from 'react';
import getFlexGap from 'src/shared/styles/emulated-flex-gap';
import theme from 'src/shared/theme';
import styled from 'styled-components';
import { XS } from 'src/shared/media-query';
import { Controller, DeepMap, FieldError, useWatch } from 'react-hook-form';
import { NewReservationInsertion } from 'src/shared/data-types/types';
import useIsMountedRef from 'src/shared/hooks/useIsMountedRef';

// eslint-disable-next-line no-control-regex
const EMAIL_REGEX = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
const NAME_REGEX = /^([-`' ]|[a-zA-Z]|[À-Ö]|[Ø-ö]|[ø-ǿ]|[Ȁ-ʯ]|[̀-ͯ]|[Ḁ-ỿ]|[Ⅰ-ↈ])+$/;
const ANIMAL_KIND_REGEX = /^([-`' ]|[a-zA-Z]|[À-Ö]|[Ø-ö]|[ø-ǿ]|[Ȁ-ʯ]|[̀-ͯ]|[Ḁ-ỿ]|[Ⅰ-ↈ])+$/;

interface Props {
  className?: string;
  registerInput: any;
  controlInput: any;
  fixPhoneNumber(newPhone: string): void;
  revalidate(fieldName: string): void;
  errors: DeepMap<NewReservationInsertion, FieldError>;
  pageContent: {
    title: string;
    dateTitle: string;
    timeTitle: string;
    personalInfo: string;
    nameTitle: string;
    phoneTitle: string;
    phoneTooltip: string;
    mailTitle: string;
    mailTooltip: string;
    animalTitle: string;
    animalKinds: { name: string }[];
    otherAnimalKind: string;
    otherAnimalsPlaceholder: string;
    purposePlaceholder: string;
    consentCheckbox: string;
    submitButton: string;
  };
}

const InputFieldsRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing(5)};

  > *:last-child {
    margin-top: ${theme.spacing(2)};
  }

  @media ${XS} {
    flex-direction: row;

    > *:first-child {
      margin-right: ${theme.spacing(4)};
      flex: 0 0 calc(50% - ${theme.spacing(2)});
    }

    > *:last-child {
      margin-top: 0;
      flex: 0 0 calc(50% - ${theme.spacing(2)});
    }
  }
`;

const BeforeInput = styled(InputAdornment)`
  margin-right: 3px;
`;

const AnimalTitle = styled.label`
  margin-bottom: ${theme.spacing(2)};
`;

const RadiosWrapper = styled.div`
  display: flex;
  align-items: stretch;
`;

const Radios = styled(RadioGroup)`
  display: flex;
  align-items: stretch;
  ${getFlexGap(4)}
`;

const RadioButton = styled(ButtonBase)`
  background: ${theme.palette.background.default};
  padding: 0.4rem 0.3rem 0.4rem 0.7rem;
  display: flex;
  border: 1px solid ${theme.palette.shadow};
  border-radius: 0.5rem;
  cursor: pointer;
  flex: 1 1 auto;

  &:hover {
    border-color: ${theme.palette.primary.main};
  }

  span[aria-disabled='false']:hover {
    background: transparent !important;
  }
`;

const AnimalKindTextField = styled(TextField)``;

const Tooltip = styled(Text)`
  align-self: center;
`;

const PersonalInfo: FC<Props> = ({
  pageContent,
  registerInput,
  controlInput,
  errors,
  fixPhoneNumber,
  revalidate,
  // eslint-disable-next-line arrow-body-style
}) => {
  const animalRadioValue = useWatch({ control: controlInput, name: `animal` });
  const [validatePhoneNumber, setValidatePhoneNumber] = useState<any>();
  const isMountedRef = useIsMountedRef();

  return (
    <>
      <InputFieldsRow>
        <TextField
          id="name"
          InputProps={{ disableUnderline: true }}
          inputProps={{ 'data-testid': `name` }}
          name="clientName"
          autoComplete="name"
          label={pageContent.nameTitle}
          variant="filled"
          required
          inputRef={registerInput({
            required: `Zadejte prosím Vaše jméno.`,
            pattern: {
              value: NAME_REGEX,
              message: `Nemáte ve jméně nějaký překlep?`,
            },
          })}
          error={!!errors.clientName}
          helperText={errors.clientName?.message}
        />
      </InputFieldsRow>
      <InputFieldsRow>
        <TextField
          id="phone"
          name="phone"
          InputProps={{
            disableUnderline: true,
            startAdornment: <BeforeInput position="start">+420</BeforeInput>,
          }}
          inputProps={{ 'data-testid': `phone` }}
          label={pageContent.phoneTitle}
          variant="filled"
          required
          onInput={({
            target: { value },
          }: React.ChangeEvent<HTMLInputElement>) => {
            if (validatePhoneNumber == null) {
              import(`libphonenumber-js`).then(({ isValidPhoneNumber }) => {
                if (isMountedRef.current) {
                  setValidatePhoneNumber(() => isValidPhoneNumber);
                  revalidate(`phone`);
                }
              });
            }

            if (value && value.indexOf(`+420`) === 0) {
              const newValue = value.replace(`+420`, ``);
              fixPhoneNumber(newValue);
            }
          }}
          inputRef={registerInput({
            required: `Zadejte prosím Váš telefon.`,
            validate: {
              format: phone => {
                const INVALID_FORMAT = `Toto není platný formát telefonního čísla.`;

                if (validatePhoneNumber == null) {
                  return `Probíhá validace formátu čísla`;
                }

                return (
                  validatePhoneNumber(`+420${phone}`, `CZ`) || INVALID_FORMAT
                );
              },
            },
          })}
          error={!!errors.phone}
          helperText={errors.phone?.message}
        />
        {/* TODO: The first sentence should be bold and on the first row alone */}
        <Tooltip variant="caption">{pageContent.phoneTooltip}</Tooltip>
      </InputFieldsRow>
      <InputFieldsRow>
        <TextField
          id="email"
          name="email"
          InputProps={{ disableUnderline: true }}
          inputProps={{ 'data-testid': `email` }}
          label={pageContent.mailTitle}
          variant="filled"
          required
          inputRef={registerInput({
            required: `Zadejte prosím Váš email.`,
            pattern: { value: EMAIL_REGEX, message: `Toto není platný email.` },
          })}
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        {/* TODO: The first sentence should be bold and on the first row alone */}
        <Tooltip variant="caption">{pageContent.mailTooltip}</Tooltip>
      </InputFieldsRow>

      <AnimalTitle htmlFor="animal">{pageContent.animalTitle}</AnimalTitle>
      <InputFieldsRow>
        <RadiosWrapper>
          <Controller
            control={controlInput}
            name="animal"
            rules={{ required: true }}
            defaultValue={pageContent.animalKinds[0].name}
            render={({ onChange, value }) => (
              <Radios id="animal" value={value} row onChange={onChange}>
                {pageContent.animalKinds.map(({ name: animal }) => (
                  <RadioButton key={animal} onClick={() => onChange(animal)}>
                    <FormControlLabel
                      value={animal}
                      control={
                        <Radio
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                        />
                      }
                      label={animal}
                    />
                  </RadioButton>
                ))}
                <RadioButton
                  key={pageContent.otherAnimalKind}
                  onClick={() => onChange(pageContent.otherAnimalKind)}
                >
                  <FormControlLabel
                    value={pageContent.otherAnimalKind}
                    control={
                      <Radio
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                      />
                    }
                    label={pageContent.otherAnimalKind}
                  />
                </RadioButton>
              </Radios>
            )}
          />
        </RadiosWrapper>
        <AnimalKindTextField
          id="other-animal-kind"
          name="other-animal-kind"
          InputProps={{ disableUnderline: true }}
          label={pageContent.otherAnimalsPlaceholder}
          variant="filled"
          required
          disabled={animalRadioValue !== pageContent.otherAnimalKind}
          inputRef={registerInput({
            required:
              animalRadioValue === pageContent.otherAnimalKind
                ? `Zadejte prosím jaké zvíře chcete ošetřit.`
                : false,
            pattern: {
              value: ANIMAL_KIND_REGEX,
              message: `Takové zvíře opravdu existuje?`,
            },
          })}
          error={!!errors[`other-animal-kind`]}
          helperText={
            errors[`other-animal-kind`]
              ? errors[`other-animal-kind`].message
              : null
          }
        />
      </InputFieldsRow>
      <InputFieldsRow>
        <TextField
          id="purpose"
          name="purpose"
          InputProps={{ disableUnderline: true }}
          inputProps={{ 'data-testid': `purpose` }}
          label={pageContent.purposePlaceholder}
          variant="filled"
          required
          multiline
          rows={5}
          inputRef={registerInput({
            required: `Zadejte prosím důvod vaší rezervace.`,
          })}
          error={!!errors.purpose}
          helperText={errors.purpose?.message}
        />
      </InputFieldsRow>
    </>
  );
};

export default PersonalInfo;
