import NewReservationForm from 'components/reservations/new-reservation-form';
import ReservationsDescription from 'components/reservations/description';
import SectionContainer from 'components/shared/section-container';
import { graphql } from 'gatsby';
import React, { useState } from 'react';
import Layout from 'src/components/shared/layout';
import { XL, SM } from 'src/shared/media-query';
import theme from 'src/shared/theme';
import styled from 'styled-components';
import {
  reservationPageSidePadding,
  PageContent,
} from 'components/reservations/shared';
import { ReservationContext } from 'src/shared/context';

const Inputs = styled(NewReservationForm)`
  @media ${SM} {
    padding-right: ${theme.spacing(12)};
    padding-left: 0;
    border-right: 1px solid ${theme.palette.shadow};
    flex: 1 1 832px;
  }

  @media ${XL} {
    padding-right: ${theme.spacing(22)};
  }
`;

const Description = styled(ReservationsDescription)`
  flex: 1 1 500px;
  ${reservationPageSidePadding};
  padding-top: ${theme.spacing(14)};

  @media ${SM} {
    padding: 0;
    padding-left: ${theme.spacing(12)};
  }

  @media ${XL} {
    padding-left: ${theme.spacing(22)};
  }
`;

const IndexPage = ({ data }) => {
  const [reservation, setReservation] = useState({ code: `` });

  return (
    <React.StrictMode>
      <Layout pageName="Rezervace">
        <ReservationContext.Provider value={{ reservation, setReservation }}>
          <SectionContainer>
            <PageContent>
              <Inputs
                pageContent={
                  data.markdownRemark.frontmatter.newReservationSection
                }
              />
              <Description
                pageContent={
                  data.markdownRemark.frontmatter.reservationsDescription
                }
              />
            </PageContent>
          </SectionContainer>
        </ReservationContext.Provider>
      </Layout>
    </React.StrictMode>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    markdownRemark(fileAbsolutePath: { regex: "/(reservations-page.md)/" }) {
      frontmatter {
        newReservationSection {
          title
          dateTitle
          timeTitle
          personalInfo
          nameTitle
          phoneTitle
          phoneTooltip
          mailTitle
          mailTooltip
          animalTitle
          animalKinds {
            name
          }
          otherAnimalKind
          otherAnimalsPlaceholder
          purposePlaceholder
          consentCheckbox
          submitButton
        }
        reservationsDescription {
          title
          reservationUsableFor
          surgeries
          description
          cancelReservationTitle
          cancelReservationDescription
          cancelReservationButton
        }
      }
    }
  }
`;
