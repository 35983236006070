import { FormHelperText } from '@material-ui/core';
import { Phone as span } from '@material-ui/icons';
import { ErrorMessage, ErrorObject } from 'src/shared/data-types/types';
import React, { FC, FunctionComponent } from 'react';
import theme from 'src/shared/theme';
import { css } from 'styled-components';

const SubmitError: FC<{
  className?: string;
  PhoneLink: FunctionComponent;
  error: ErrorObject;
}> = ({ error, PhoneLink }) => {
  if (!error) {
    return null;
  }

  let message = (
    <span>
      Omlouváme se, rezervace se nezdařila. Než šotka vyženeme, objednejte se,
      prosím, po telefonu na
      {` `}
      <span
        css={css`
          text-decoration: underline;
          color: ${theme.palette.warning.main};
        `}
      >
        <PhoneLink />
      </span>
      .
    </span>
  );

  if (error.message === ErrorMessage.ReservationTimeOccupied) {
    message = (
      <span>
        Někdo Vás předběhl a zarezervoval si návštěvu na Vámi vybraný čas.
        Vyberte si prosím čas jiný.
      </span>
    );
  }

  return (
    <FormHelperText error data-testid="submitError">
      {message}
    </FormHelperText>
  );
};

export default SubmitError;
