import { STATUS } from './enums';

export type ISODate = string & { isISODate: true };

export interface DBTable {
  readonly id?: number;
}

export interface NewReservationInsertionFromPublic {
  start: ISODate;
  animal: string;
  email: string;
  clientName: string;
  phone: string;
  purpose: string;
}

export interface NewReservationInsertionFromAdmin
  extends NewReservationInsertionFromPublic {
  end: ISODate;
}

export interface ReservationForPublic {
  start: ISODate;
  end: ISODate;
  status: STATUS;
  purpose: string;
}

export interface Reservation extends ReservationForPublic {
  code: string;
}

export interface ReservationForAdmin extends Reservation {
  animal: string;
  email: string;
  clientName: string;
  phone: string;
}

export interface ReservationParsed extends DBTable, DateInterval {
  start: Date;
  end: Date;
  code?: string;
  status: STATUS;
}

export interface DateInterval {
  start: Date;
  end: Date;
}

export interface ErrorObject {
  error?: any;
  message: ErrorMessage | string;
}

export enum ErrorMessage {
  ReservationTimeOccupied = `Another reservation exists with the same time`,
}

export type OccupiedReservationError = {
  actualReservations: ReservationForPublic[] | ReservationForAdmin[];
} & ErrorObject;
