import {
  ErrorMessage,
  OccupiedReservationError,
  Reservation,
  ReservationForPublic,
  ReservationParsed,
} from 'src/shared/data-types/types';
import parseReservation from 'src/shared/utils/reservations/parseReservation';
import { NewReservationFormData } from './types';

interface Props {
  reservationData: NewReservationFormData;
  setLoading(boolean): void;
  onReservationSaved(reservation: Reservation);
  onReservationOccupied(actualizedReservations: ReservationParsed[]);
  onError(error: any): void;
}

export default async function submitNewReservationForm({
  reservationData,
  setLoading,
  onReservationSaved,
  onReservationOccupied,
  onError,
}: Props) {
  setLoading(true);

  try {
    const response = await fetch(`/.netlify/functions/set-reservation`, {
      method: `POST`,
      body: JSON.stringify(reservationData),
      headers: {
        'Content-Type': `application/json`,
      },
    });

    if (response.status === 404) {
      throw new Error(`Collection not found`);
    }
    if (!response.ok) {
      const error = await response.json();
      throw error;
    }

    const reservation: Reservation = await response.json();
    onReservationSaved(reservation);
  } catch (err) {
    if (err.message === ErrorMessage.ReservationTimeOccupied) {
      const { actualReservations } = err as OccupiedReservationError;
      const parsedReservations = (actualReservations as ReservationForPublic[]).map(
        parseReservation
      );

      onReservationOccupied(parsedReservations);
    }

    onError(err);
  } finally {
    setLoading(false);
  }
}
