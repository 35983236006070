import { ListItem } from '@material-ui/core';
import Text from 'components/shared/text';
import React, { FC } from 'react';
import theme from 'src/shared/theme';
import styled from 'styled-components';

interface Props {
  className?: string;
}

const Bullet = styled(Text)`
  padding-right: ${theme.spacing(2)};
`;

const ListItemWithBullet: FC<Props> = (props) => (
  <ListItem {...props}>
    <Bullet color="primary">•</Bullet>
    {props.children}
  </ListItem>
);

export default ListItemWithBullet;
