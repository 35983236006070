import { ButtonBase } from '@material-ui/core';
import React, { FC } from 'react';

import { format } from 'date-fns';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';

import Text from 'components/shared/text';
import theme from 'src/shared/theme';
import { XS } from 'src/shared/media-query';
import getFlexGap from 'src/shared/styles/emulated-flex-gap';
import { ButtonData } from './utils/use-buttons-map-from-date';

interface Props {
  className?: string;
  buttonsData: ButtonData[];
  defaultSelection?: ButtonData;
  control: any;
}

const Wrapper = styled.div`
  ${getFlexGap(4)}
  max-width: 700px;
  align-self: center;
  justify-content: center;

  @media ${XS} {
    ${getFlexGap(5)}
  }
`;

const TermButton = styled(ButtonBase)`
  color: ${theme.palette.primary.dark};
  border: 3px solid ${theme.palette.shadow};
  border-radius: 0.5rem;
  background-color: ${theme.palette.common.white};
  padding: ${theme.spacing(3)} ${theme.spacing(6)};
  width: 6rem;

  @media ${XS} {
    padding: ${theme.spacing(1)} ${theme.spacing(12)};
    width: 9.4rem;
  }
`;

const DisabledTermButton = styled(TermButton)`
  color: #aab7cb;
  border-color: ${theme.palette.common.white};
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    /* OccupiedText cannot override this while it is being set on the DisabledTermButton */
    text-decoration: line-through;
  }
`;

const SelectedTermButton = styled(TermButton)`
  color: ${theme.palette.primary.contrastText};
  background-color: ${theme.palette.primary.main};
  border-color: ${theme.palette.primary.main};
`;

// TODO: this should be radio button from the aria perspective
const VariableTermButton: FC<{
  className?: string;
  onClick: () => void;
  disabled: boolean;
  selected: boolean;
}> = ({ disabled, selected, children, ...rest }) => {
  if (disabled) {
    return (
      <DisabledTermButton {...rest} disabled>
        {children}
      </DisabledTermButton>
    );
  }

  if (selected) {
    return (
      <SelectedTermButton {...rest} aria-pressed>
        {children}
      </SelectedTermButton>
    );
  }

  return <TermButton {...rest}>{children}</TermButton>;
};

const TimePicker: FC<Props> = ({ buttonsData, defaultSelection, control }) => (
  <Wrapper data-testid="timePicker">
    <Controller
      control={control}
      name="start"
      rules={{ required: defaultSelection ? false : `Není vybrán žádný čas rezervace.` }}
      // this is sadly not used as real value, so it isn't valid and it isn't submited either
      defaultValue={defaultSelection || new Date(null as any)}
      render={({ onChange }) => (
        <>
          {buttonsData.map(button => (
            <VariableTermButton
              disabled={button.disabled}
              selected={button.selected}
              key={button.time.toISOString()}
              onClick={() => {
                onChange(button.time);
              }}
              data-testid={`timePicker-${format(button.time, `HHmm`)}`}
            >
              <Text fontWeight="bold" color="inherit">
                {format(button.time, `HH:mm`)}
              </Text>
            </VariableTermButton>
          ))}
        </>
      )}
    />
  </Wrapper>
);

export default TimePicker;
