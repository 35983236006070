import { LinkProps } from '@material-ui/core';
import PhoneLink from 'components/shared/phone-link';
import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';

interface Props {
  className?: string;
  secondPhone?: boolean;
}

const ContactPhone: FC<Props & LinkProps> = (props) => {
  const {
    markdownRemark: {
      frontmatter: { phones },
    },
  } = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { id: { eq: "welcome-section" } }) {
          frontmatter {
            phones
          }
        }
      }
    `,
  );

  const phone = props.secondPhone && phones.length > 1 ? phones[1] : phones[0];

  return <PhoneLink phone={phone} color="inherit" {...props} />;
};

export default ContactPhone;
