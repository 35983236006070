import { ReservationParsed } from 'src/shared/data-types/types';
import { isBefore, format } from 'date-fns';
import { useEffect, useState } from 'react';
import {
  isIntervalReserved,
  splitIntoIntervals,
} from 'src/shared/utils/reservations/reservations';

export interface ButtonData {
  selected: boolean;
  time: Date;
  disabled: boolean;
}

export default function useButtonsMapFromDate(
  selectedTerm: Date,
  reservations: ReservationParsed[]
): [ButtonData[], ButtonData | undefined] {
  const [buttonsData, setButtonsData] = useState<ButtonData[]>([]);
  const [selectedButton, setSelectedButton] = useState<
    ButtonData | undefined
  >();

  useEffect(() => {
    const allDayIntervals = splitIntoIntervals({ date: selectedTerm });
    const selectedTime = format(selectedTerm, `HH:mm`);

    let someIntervalSelected = false;
    const mappedIntervals = allDayIntervals.map(interval => {
      const { start } = interval;
      const disabled =
        isBefore(start, new Date()) ||
        isIntervalReserved({
          interval,
          reservations,
        });

      const selected =
        !disabled && selectedTime === format(interval.start, `HH:mm`);

      if (selected) {
        someIntervalSelected = true;
      }

      return { time: start, disabled, selected };
    });

    if (!someIntervalSelected) {
      const firstEnabledInterval = mappedIntervals.find(
        interval => !interval.disabled
      );

      if (firstEnabledInterval) {
        firstEnabledInterval.selected = true;
      }
    }

    setButtonsData(mappedIntervals);
    setSelectedButton(mappedIntervals.find(interval => interval.selected));
  }, [reservations, selectedTerm]);

  return [buttonsData, selectedButton];
}
