import { DateInterval, ReservationParsed } from 'src/shared/data-types/types';
import {
  setHours,
  setMinutes,
  addSeconds,
  addMinutes,
  setSeconds,
} from 'date-fns';

const GATSBY_RESERVATION_LENGHT_IN_MINUTES = Number(
  process.env.GATSBY_RESERVATION_LENGHT_IN_MINUTES
);
const GATSBY_RESERVATIONS_HOURS_FROM = Number(process.env.GATSBY_RESERVATIONS_HOURS_FROM);
const GATSBY_RESERVATIONS_HOURS_TO = Number(process.env.GATSBY_RESERVATIONS_HOURS_TO);

const WORKING_HOURS_IN_MINUTES =
  (GATSBY_RESERVATIONS_HOURS_TO - GATSBY_RESERVATIONS_HOURS_FROM) * 60;
const TOTAL_INTERVALS =
  WORKING_HOURS_IN_MINUTES / GATSBY_RESERVATION_LENGHT_IN_MINUTES;

if (!process.env.GATSBY_RESERVATION_LENGHT_IN_MINUTES) {
  throw new Error(`GATSBY_RESERVATION_LENGHT_IN_MINUTES env var was not set! Or it just has invalid value? ${process.env.GATSBY_RESERVATION_LENGHT_IN_MINUTES}`);
}
if (!process.env.GATSBY_RESERVATIONS_HOURS_FROM) {
  throw new Error(`GATSBY_RESERVATIONS_HOURS_FROM env var was not set! Or it just has invalid value? ${process.env.GATSBY_RESERVATIONS_HOURS_FROM}`);
}
if (!process.env.GATSBY_RESERVATIONS_HOURS_TO) {
  throw new Error(`GATSBY_RESERVATIONS_HOURS_TO env var was not set! Or it just has invalid value? ${process.env.GATSBY_RESERVATIONS_HOURS_TO}`);
}

export function splitIntoIntervals({ date }: { date: Date }) {
  const possibleIntervals = [] as { start: Date; end: Date }[];

  const firstStart = setSeconds(
    setMinutes(setHours(date, GATSBY_RESERVATIONS_HOURS_FROM), 0),
    0
  );

  for (let i = 0; i < TOTAL_INTERVALS; i++) {
    const previousInterval = possibleIntervals[i - 1];

    const start = previousInterval
      ? previousInterval.end
      : firstStart;
    const end = addMinutes(start, GATSBY_RESERVATION_LENGHT_IN_MINUTES);

    possibleIntervals.push({ start, end });
  }

  return possibleIntervals;
}

export function isIntervalReserved({
  interval,
  reservations,
}: {
  interval: DateInterval;
  reservations: ReservationParsed[];
}) {
  function isInReservedInterval({ start, end, status }: ReservationParsed) {
    // **********>-----<***********
    // ---->-------<---------------
    // ---->---<-------------------
    // ----------->---<------------
    // -------------->-----<-------
    const startsBeforeReservationEnd = start < interval.end;
    const endsAfterReservationStart = end > interval.start;

    return (
      status === `ACTIVE` &&
      startsBeforeReservationEnd &&
      endsAfterReservationStart
    );
  }

  return reservations.some(isInReservedInterval);
}

export function areAllIntervalsReserved({
  intervals,
  reservations,
}: {
  intervals: DateInterval[];
  reservations: ReservationParsed[];
}) {
  return intervals.every(interval =>
    isIntervalReserved({ interval, reservations }));
}

export function isWholeDayReserved({
  day,
  reservations,
}: {
  day: Date;
  reservations: ReservationParsed[];
}) {
  const workingHourIntervals = splitIntoIntervals({ date: day });
  return areAllIntervalsReserved({
    intervals: workingHourIntervals,
    reservations,
  });
}
