import { FormHelperText } from '@material-ui/core';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import DoneIcon from '@material-ui/icons/Done';

import Text from 'components/shared/text';
import theme from 'src/shared/theme';
import { reservationPageSidePadding } from '../shared';

interface Props {
  className?: string;
  step?: number;
  stepsCount: number | 0;
  title: string;
  errorMessage?: string;
  ref?: React.Ref<HTMLDivElement>;
}

const Wrapper = styled.div<{ $showError }>`
  ${({ $showError }) => ($showError ? `border: 1px solid ${theme.palette.warning.main};` : null)}
`;

const Heading = styled.div<{ $showError }>`
  background-color: ${theme.palette.primary.light};
  padding: ${theme.spacing(3)} ${theme.spacing(9)};
  display: flex;
  align-items: baseline;
  ${({ $showError }) => ($showError
    ? `border-bottom: 1px solid ${theme.palette.warning.main};`
    : null)}
`;

const Step = styled.div`
  border-radius: 1000px;
  width: 3rem;
  height: 3rem;
  background-color: ${theme.palette.primary.main};
  color: ${theme.palette.primary.contrastText};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${theme.spacing(4)};
`;

const TitleWrapper = styled.div`
  flex: 1 1 auto;
  flex-direction: column;
  align-items: baseline;
  position: relative;
`;

const ErrorMessage = styled(FormHelperText)`
  position: absolute;
  bottom: -1rem;
  color: ${theme.palette.warning.main};
`;

const Content = styled.div`
  background-color: ${theme.palette.primary.lightest};
  display: flex;
  flex-direction: column;
  ${reservationPageSidePadding}
`;

const InputBox: FC<Props> = React.forwardRef((props, ref: any) => (
  <Wrapper
    className={props.className}
    $showError={props.errorMessage}
    ref={ref}
  >
    <Heading $showError={props.errorMessage}>
      <Step>
        <Text variant="h5" color="inherit" css={css`display: flex;`}>
          {props.stepsCount ? props.step : <DoneIcon />}
        </Text>
      </Step>
      <TitleWrapper>
        <Text variant="h5">{props.title}</Text>
        <ErrorMessage>{props.errorMessage}</ErrorMessage>
      </TitleWrapper>
      <Text variant="body1">
        {props.stepsCount ? `${props.step}/${props.stepsCount}` : ``}
      </Text>
    </Heading>
    <Content>{props.children}</Content>
  </Wrapper>
));

export default InputBox;
