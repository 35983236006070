import Text from 'components/shared/text';
import {
  Reservation,
  ReservationParsed,
  ISODate,
} from 'src/shared/data-types/types';
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { startOfDay } from 'date-fns';
import { useForm, useWatch, FieldError, Controller } from 'react-hook-form';
import { Checkbox, FormHelperText, Link } from '@material-ui/core';

import theme from 'src/shared/theme';
import styled, { css } from 'styled-components';
import Button from 'components/shared/button';
import useFetch from 'src/shared/hooks/useFetch';
import parseReservation from 'src/shared/utils/reservations/parseReservation';
import ContactPhone from 'components/shared/contact-phone';
import { SM, XS } from 'src/shared/media-query';
import Spinner from 'components/shared/spinner/spinner';
import useIsMountedRef from 'src/shared/hooks/useIsMountedRef';
import { ReservationContext } from 'src/shared/context';
import scrollToElement from 'src/shared/scroll-to-ref';
import { CONSENT } from 'src/shared/urls';
import TimePicker from './time-picker';
import PersonalInfo from './personal-info';
import ReservationDatePicker from './date-picker/reservation-date-picker';
import InputBox from './input-box';
import Success from './success';
import useButtonsMapFromDate from './utils/use-buttons-map-from-date';
import { allReservedReservation } from '../shared';
import SubmitError from './submit-error';
import getAnimal from './utils/get-animal';
import { NewReservationFormData } from './utils/types';
import submitNewReservationForm from './utils/submit-new-reservation-form';
import getTerm from './utils/get-term';

interface Props {
  className?: string;
  pageContent: {
    title: string;
    dateTitle: string;
    timeTitle: string;
    personalInfo: string;
    nameTitle: string;
    phoneTitle: string;
    phoneTooltip: string;
    mailTitle: string;
    mailTooltip: string;
    animalTitle: string;
    animalKinds: { name: string }[];
    otherAnimalKind: string;
    otherAnimalsPlaceholder: string;
    purposePlaceholder: string;
    consentCheckbox: string;
    submitButton: string;
  };
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Text)`
  margin-left: ${theme.spacing(10)};
  margin-right: ${theme.spacing(10)};

  @media ${SM} {
    margin-left: 0;
    margin-right: 0;
  }
`;

const InputsWrapper = styled.div`
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-top: ${theme.spacing(8)};
`;

const DatePickerWrapper = styled.div`
  align-self: center;
`;

const SubmitWrapper = styled.div`
  display: flex;
  margin-top: ${theme.spacing(10)};
  flex-direction: column;
  align-items: flex-start;

  @media ${XS} {
    flex-direction: row;
    align-items: center;
  }
`;

const SubmitButton = styled(Button)`
  margin-bottom: ${theme.spacing(4)};
  flex: 1 0 auto;

  @media ${XS} {
    margin-bottom: 0;
    margin-right: ${theme.spacing(8)};
    flex: 0 0 15rem;
  }
`;

const STEPS_COUNT = 3;

const NewReservationForm: FC<Props> = ({ pageContent, className }) => {
  const otherAnimalKindLabel = pageContent.otherAnimalKind;

  const {
    register,
    handleSubmit,
    control,
    errors,
    setValue,
    trigger,
  } = useForm<NewReservationFormData & { consent: boolean }>();

  const { setReservation: setReservationToContext } = useContext(
    ReservationContext
  );

  const reservationTimePickerRef = useRef(null);
  const reservationSuccessRef = useRef(null);
  const isMountedRef = useIsMountedRef();

  const [saving, setSaving] = useState(false);
  const defaultDate = startOfDay(new Date());
  const [date, setDate] = useState(defaultDate);
  const time = useWatch({ control, name: `start`, defaultValue: defaultDate });
  const term = useMemo(() => getTerm({ date, time }), [date, time]);

  const [submitError, setSubmitError] = useState<any>();
  const [savedReservation, setSavedReservation] = useState<Reservation>();
  const [confirmedReservations, setConfirmedReservations] = useState(
    [] as ReservationParsed[]
  );

  const [fetchedReservations, loading] = useFetch<Reservation>(
    `/.netlify/functions/get-future-reservations`,
    {
      defaultData: [allReservedReservation],
    }
  );

  useEffect(() => {
    setConfirmedReservations(fetchedReservations.map(parseReservation));
  }, [fetchedReservations]);

  useEffect(() => {
    if (errors.start && reservationTimePickerRef.current) {
      scrollToElement(reservationTimePickerRef.current);
    }
  }, [errors.start]);

  useEffect(() => {
    if (savedReservation && reservationSuccessRef.current) {
      scrollToElement(reservationSuccessRef.current);
    }
  }, [savedReservation]);

  const [timePickerButtonsData, selectedTimeButton] = useButtonsMapFromDate(
    term,
    confirmedReservations
  );

  const submit = useCallback(
    (inputsData: NewReservationFormData) => {
      const reservationData = {
        ...inputsData,
        phone: `+420${inputsData.phone}`,
        start: selectedTimeButton?.time.toISOString() as ISODate,
        animal: getAnimal(inputsData, otherAnimalKindLabel),
      } as NewReservationFormData;

      // TODO: use AbortController instead
      return submitNewReservationForm({
        reservationData,
        onError: err => {
          if (isMountedRef.current) {
            setSubmitError(err);
          }
        },
        setLoading(isLoading) {
          if (isMountedRef.current) {
            setSaving(isLoading);
          }
        },
        onReservationSaved(reservation) {
          if (isMountedRef.current) {
            setSavedReservation(reservation);
            setReservationToContext({ code: reservation.code });
            setSubmitError(null);
          }
        },
        onReservationOccupied(actualizedReservations) {
          if (isMountedRef.current) {
            setConfirmedReservations(actualizedReservations);
          }
        },
      });
    },
    [
      selectedTimeButton?.time,
      otherAnimalKindLabel,
      isMountedRef,
      setReservationToContext,
    ]
  );

  return (
    <Container className={className}>
      <Title variant="h3" variantMapping={{ h3: `h1` }}>
        {pageContent.title}
      </Title>
      {!savedReservation ? (
        <form
          onSubmit={handleSubmit(submit)}
          aria-label={pageContent.title}
          noValidate
          data-testid="form"
        >
          <InputsWrapper>
            <InputBox
              title={pageContent.dateTitle}
              step={1}
              stepsCount={STEPS_COUNT}
            >
              <DatePickerWrapper
                css={css`
                  position: relative;
                `}
                data-testid="datePicker"
              >
                <ReservationDatePicker
                  onDateSelected={setDate}
                  reservations={confirmedReservations}
                  selectedDate={date}
                />
                {loading && <Spinner />}
              </DatePickerWrapper>
            </InputBox>

            <InputBox
              title={pageContent.timeTitle}
              step={2}
              stepsCount={STEPS_COUNT}
              errorMessage={(errors.start as FieldError)?.message}
              ref={reservationTimePickerRef}
            >
              <TimePicker
                defaultSelection={selectedTimeButton}
                buttonsData={timePickerButtonsData}
                control={control}
              />
            </InputBox>

            <InputBox
              title={pageContent.personalInfo}
              step={3}
              stepsCount={STEPS_COUNT}
            >
              <PersonalInfo
                pageContent={pageContent}
                registerInput={register}
                controlInput={control}
                errors={errors}
                fixPhoneNumber={newPhone =>
                  setValue(`phone`, newPhone, { shouldValidate: true })
                }
                revalidate={trigger}
              />
              <Controller
                name="consent"
                control={control}
                defaultValue={false}
                rules={{ required: true }}
                render={props => (
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <Checkbox
                      onChange={e => props.onChange(e.target.checked)}
                      checked={props.value}
                      color="primary"
                      id="consent"
                      required
                    />
                    <Text>
                      Souhlasím se
                      {` `}
                      <Link href={CONSENT} rel="noopener" target="_blank">
                        zpracováním osobních údajů
                      </Link>
                      {` `}
                      pro potřeby rezervace.
                    </Text>
                    <FormHelperText
                      error={!!errors.consent}
                      hidden={!errors.consent}
                      variant="filled"
                    >
                      Musíte souhlasit se zpracováním údajů
                    </FormHelperText>
                  </div>
                )}
              />
              <SubmitWrapper>
                <SubmitButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  data-testid="submit"
                  loading={saving}
                  disabled={timePickerButtonsData.every(
                    button => button.disabled
                  )}
                >
                  {pageContent.submitButton}
                </SubmitButton>
                <SubmitError error={submitError} PhoneLink={ContactPhone} />
              </SubmitWrapper>
            </InputBox>
          </InputsWrapper>
        </form>
      ) : (
        <InputsWrapper>
          <InputBox
            title="Rezervace byla odeslána"
            stepsCount={0}
            ref={reservationSuccessRef}
          >
            <Success reservation={savedReservation} />
          </InputBox>
        </InputsWrapper>
      )}
    </Container>
  );
};

export default NewReservationForm;
