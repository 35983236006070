import { ReservationForPublic, ReservationParsed } from 'src/shared/data-types/types';

function parseReservation(reservation: ReservationForPublic) {
  const parsedReservation = {
    ...reservation,
    start: new Date(reservation.start),
    end: new Date(reservation.end),
  } as ReservationParsed;

  return parsedReservation;
}

export default parseReservation;
