import { format } from 'date-fns';
import React, { FC } from 'react';
import { css } from 'styled-components';

import Button from 'components/shared/button';
import Text from 'components/shared/text';
import { Reservation } from 'src/shared/data-types/types';
import useStoredReservation from 'src/shared/hooks/useStoredReservation';
import theme from 'src/shared/theme';

interface Props {
  className?: string;
  reservation: Reservation;
}

const Success: FC<Props> = ({ reservation }) => {
  const { queryParamsForDelete } = useStoredReservation();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
      data-testid="reservation-success"
    >
      <Text
        css={css`
          margin-bottom: ${theme.spacing(2)};
        `}
        data-testid="reservation-success-date"
      >
        <b>Datum Vaší rezervace: </b>
        {format(new Date(reservation.start), `d. MMMM yyyy`)}
      </Text>
      <Text
        css={css`
          margin-bottom: ${theme.spacing(2)};
        `}
        data-testid="reservation-success-time"
      >
        <b>Čas rezervace: </b>
        {format(new Date(reservation.start), `H:mm`)}
      </Text>
      <Text data-testid="reservation-success-code">
        <b>Kód rezervace: </b>
        {reservation.code}
      </Text>
      <Text
        variant="caption"
        color="textSecondary"
        data-testid="reservation-success-code-message"
      >
        Kód slouží pro případné zrušení rezervace.
      </Text>
      <Button
        to={`/rezervace/zrusit${queryParamsForDelete}`}
        color="secondary"
        variant="contained"
        css={css`
          margin-top: ${theme.spacing(8)};
          align-self: flex-start;
        `}
        data-testid="reservation-success-delete-button"
      >
        Zrušit rezervaci
      </Button>
    </div>
  );
};

export default Success;
