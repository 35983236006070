import { NewReservationFormData } from './types';

export default function getAnimal(
  inputsData: NewReservationFormData,
  otherAnimalKindLabel: string
) {
  const animal =
    inputsData.animal === otherAnimalKindLabel
      ? inputsData[`other-animal-kind`]
      : inputsData.animal;

  return animal;
}
