import { useContext, useEffect, useState } from 'react';
import { ReservationContext } from '../context';

export default function useStoredReservation() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { reservation } = useContext(ReservationContext);

  const [queryParamsForDelete, setQueryParamsForDelete] = useState(``);

  useEffect(() => {
    if (reservation?.code) {
      setQueryParamsForDelete(`?code=${reservation.code}`);
    }
  }, [reservation]);

  return { queryParamsForDelete };
}
